"use client";
import Button from "components/Button";
import { ButtonAppearance } from "utils/constants";

const RecordPageNotFound = () => {
  return (
    <div className="flex h-full w-full flex-col items-center justify-center gap-4">
      <div className="flex items-center gap-2">
        <h1 className="inline-block text-md font-semibold">404</h1>
        <h2 className="inline-block border-l border-l-black pl-2 text-sm dark:border-l-white">
          This page could not be found.
        </h2>
      </div>
      <Button
        label="Go to Home"
        appearance={ButtonAppearance.TERTIARY}
        className="!text-base-primary dark:!text-base-dark-primary"
        onClick={() => {
          window.location.replace("/");
        }}
      />
    </div>
  );
};

export default RecordPageNotFound;
